'use client'

/* eslint-disable import/namespace */
import { type DehydratedState } from '../generated/hypertune'
import { HypertuneProvider, useHypertune } from '../generated/hypertune.react'
import React from 'react'
import { getHypertuneCookieClient } from './hypertune-cookie-client'
import * as Sentry from '@sentry/nextjs'
import { type FetchUserAttributesOutput } from '@aws-amplify/auth'

const HypertuneLayoutInner = () => {
  const hypertune = useHypertune()
  const isReady = hypertune.isReady()
  try {
    if (isReady) {
      hypertune.startSession()
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  return <></>
}

export const HypertuneLayoutProvider = ({
  children,
  dehydratedState,
  user,
}: {
  children: React.ReactNode
  dehydratedState?: DehydratedState | null | undefined
  user?: FetchUserAttributesOutput | null
}) => {
  const cookie = getHypertuneCookieClient()
  return (
    <HypertuneProvider
      dehydratedState={dehydratedState}
      createSourceOptions={{
        branchName: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF as string,
        token: process.env.NEXT_PUBLIC_HYPERTUNE_TOKEN as string,
        initIntervalMs: Number.parseInt(
          process.env.NEXT_PUBLIC_HYPERTUNE_RESYNC_INTERVAL_CLIENT ?? '0',
          10,
        ),
        remoteLogging: {
          mode:
            process.env.NEXT_PUBLIC_LOG_HYPERTUNE === 'true'
              ? 'session'
              : 'off',
        },
      }}
      rootArgs={{
        context: {
          environment: (process.env.NEXT_PUBLIC_ENV as string).toUpperCase() as
            | 'PROD'
            | 'PREVIEW'
            | 'LOCAL'
            | 'STAGING',
          useNewCart:
            (process.env.NEXT_PUBLIC_USE_NEW_CART as string) === 'true',
          user: {
            id: cookie ?? '',
            email: user?.email?.toLowerCase() ?? 'anonymous',
            loggedIn: user == null ? 'false' : 'true',
          },
        },
      }}
    >
      <HypertuneLayoutInner />
      {children}
    </HypertuneProvider>
  )
}
