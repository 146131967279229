/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
/* eslint-disable import/namespace */
'use client'

import {
  type FC,
  type PropsWithChildren,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import {
  type FetchUserAttributesOutput,
  fetchUserAttributes,
} from '@aws-amplify/auth'
import dynamic from 'next/dynamic'
import { LoginContext, type LoginContextProps } from './use-login'
import { Modal } from '@/components/library/modal'
import { getUser } from '@/lib/data/get-user'
import updatePostcode from '@/components/user/update-postcode-server'
import {
  getPostcodeClient,
  setPostcodeClient,
} from '@/lib/data/postcode-client'
import { Hub } from '@aws-amplify/core'
import {
  setBasketCookieSession,
  deleteBasketClient,
} from '@/lib/data/basket-cookie-client'
import { type User } from '@/lib/generated/graphql'
import { type DehydratedState } from '@/lib/generated/hypertune'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/navigation'
import { routeCalculatorRelative } from '@/lib/route-calculator'

const LoginModal = dynamic(
  async () => await import('../login-modal').then((mod) => mod.LoginModal),
)

const OnboardingContainer = dynamic(
  async () => await import('@/components/user-prefs/onboarding-container'),
)

const PostCodeModal = dynamic(
  async () =>
    await import('@/components/user-prefs/postcode-form').then(
      (mod) => mod.PostcodeForm,
    ),
)

export const LoginProvider: FC<
  PropsWithChildren<{
    dehydratedState?: DehydratedState | null | undefined
    initialUserAttributes?: FetchUserAttributesOutput | null
  }>
> = ({ children, initialUserAttributes }) => {
  const [loggedIn, _setLoggedIn] = useState<boolean>(
    initialUserAttributes != null,
  )
  const [showPostcode, setShowPostcode] = useState<boolean>(false)
  const [showLogin, setShowLogin] = useState<boolean>(false)
  const [user, setUser] = useState<User>()
  const router = useRouter()

  const [userAttributes, setUserAttributes] = useState<
    FetchUserAttributesOutput | undefined
  >(initialUserAttributes ?? undefined)

  const openOnboarding = useCallback(() => {
    setOpen(true)
  }, [])

  const openLogin = useCallback(() => {
    setShowLogin(true)
  }, [])

  const setLoggedIn = useCallback(
    (loggedIn: boolean) => {
      if (loggedIn) {
        setShowLogin(false)
      }
      if (loggedIn) {
        // eslint-disable-next-line promise/catch-or-return
        getUser()
          .then((user) => {
            if (user == null) {
              openOnboarding()
            } else {
              setUser(user)
              if (
                user?.__typename === 'user' &&
                user?.preferences?.postCodes?.[0] != null &&
                getPostcodeClient() !== user.preferences.postCodes[0] &&
                user.preferences.postCodes[0].length === 4
              ) {
                updatePostcode(user.preferences.postCodes[0])
              }
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
          })
          .finally(() => {
            setBasketCookieSession()
          })
        // eslint-disable-next-line promise/catch-or-return
        fetchUserAttributes().then((attributes) => {
          setUserAttributes(attributes)
        })
      }
      _setLoggedIn(loggedIn)
    },
    [openOnboarding],
  )

  const [open, setOpen] = useState(false)

  useEffect(() => {
    Hub.listen('auth', (data) => {
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (data.payload.event) {
        case 'signedIn':
          Sentry.setUser({
            id: data.payload.data.userId,
          })
          setLoggedIn(true)
          break
        case 'signedOut':
          Sentry.setUser(null)
          setLoggedIn(false)
          // eslint-disable-next-line unicorn/no-useless-undefined
          setUserAttributes(undefined)
          setPostcodeClient('2000')
          deleteBasketClient()
          router.replace(routeCalculatorRelative.home)
          router.refresh()
          break
      }
    })
  }, [router, setLoggedIn, setUserAttributes])

  const closeOnboarding = useCallback(() => {
    setOpen(false)
    getUser().then((user) => {
      if (user != null) {
        setUser(user)
      }
    })
  }, [])

  const context: LoginContextProps = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
      userAttributes,
      setUserAttributes,
      openOnboarding,
      closeOnboarding,
      setShowPostcode,
      openLogin,
      user,
    }),
    [
      closeOnboarding,
      loggedIn,
      openLogin,
      openOnboarding,
      setLoggedIn,
      userAttributes,
      user,
    ],
  )

  if (userAttributes != null && user?.userId != null) {
    Sentry.setUser({
      id: userAttributes.userId,
      email: userAttributes.email,
    })
  }

  return (
    <LoginContext.Provider value={context}>
      {children}
      <Modal open={open}>
        <OnboardingContainer />
      </Modal>

      <Modal
        open={showPostcode}
        handleClose={() => {
          setShowPostcode(false)
        }}
      >
        <PostCodeModal
          handleClose={() => {
            setShowPostcode(false)
          }}
        />
      </Modal>

      <Modal
        open={showLogin}
        handleClose={() => {
          setShowLogin(false)
        }}
      >
        <LoginModal
          showModal={showLogin}
          onModalClose={() => {
            setShowLogin(false)
          }}
        />
      </Modal>
    </LoginContext.Provider>
  )
}
