import { type OrderErrors } from '@/components/order/order-placed/error-page'
import { type Category, type Maybe } from '@/lib/generated/graphql'
import { slugify } from '@/utils/functions/slugify'
import { getAbsoluteUrl } from '@/utils/functions/url-utils'
import { isNullOrEmpty } from '@/utils/string-extensions'

const products = '/products'
const home = '/'
const index = '/index.html'
const retailers = '/retailers'
const catalogues = '/catalogues'
const promotions = '/promotions'
const search = '/search'
const category = '/categories'
const about = '/about'
const help = '/help'
const siteMap = '/sitemap'
const advertise = '/advertise'
const brands = '/brands'
const account = '/account'
const wishlist = '/wishlist'
const accountDetails = '/account/my-details'
const accountPreferences = '/account/preferences'
const cart = '/cart'
const checkout = '/cart/checkout'
const cartReview = '/cart/review'
const cartPaidWithAfterpay = '/cart/paid-with-afterpay'
const cancelled = '/cart/cancelled'
const support = '/support'
const contactUs = '/contact-us'
const contactUsSuccess = '/contact-us/success'
const returnsRequest = '/returns/request'
const orderPlaced = '/cart/orderplaced'
const order = '/order'
const stores = '/stores'
const searchProductsRoute = '/search/products'
const searchCataloguesRoute = '/search/catalogues'
const terms = '/terms-and-conditions'
const incentiveterms = '/incentiveterms'
const privacy = '/privacy'
const login = '/login'
const postcode = '/postcode'
const trustpilot = '/trustpilot-reviews'

export const routeCalculatorRelative = {
  cart,
  checkout,
  privacy,
  cartReview,
  cartPaidWithAfterpay,
  cancelled,
  order,
  home,
  index,
  products,
  retailers,
  catalogues,
  search,
  category,
  about,
  help,
  siteMap,
  advertise,
  terms,
  incentiveterms,
  brands,
  promotions,
  account,
  accountDetails,
  accountPreferences,
  wishlist,
  support,
  contactUs,
  contactUsSuccess,
  returnsRequest,
  stores,
  login,
  postcode,
  trustpilot,
  orderPlaced,
  orderError: (errorCode: Maybe<OrderErrors>) =>
    `${cart}/order-error/${errorCode}`,
  orderPlacedRoute: (orderId?: Maybe<string>, error?: Maybe<OrderErrors>) =>
    `${orderPlaced}/${orderId}${error ? '/' + error : ''}`,
  accountRoute: (
    route:
      | 'my-details'
      | 'personalisation'
      | 'notifications'
      | 'email-and-sms'
      | 'orders'
      | 'recently-viewed'
      | null,
  ) => `/account/${route}`,
  brandsRoute: (brandName: string | null) =>
    `${brands}/${slugify(brandName ?? '')}`,
  productRoute: (args: {
    retailerName: string
    productId: string
    productName?: string | null
    varient?: string | null
  }) =>
    `${products}/${isNullOrEmpty(args.retailerName) ? 'retailer' : slugify(args.retailerName)}/${args.productId}/${slugify(
      args.productName ?? 'product',
    )}`,
  catalogueRoute: (args: {
    retailerName: string
    catalogueId: string
    catalogueName: string
    catalogueType: string
  }) =>
    `${
      args.catalogueType === 'CATALOGUE_VIRTUAL' ? promotions : catalogues
    }/${slugify(args.retailerName)}/${args.catalogueId}/${slugify(
      args.catalogueName,
    )}`,
  categoriesRoute: (categoryForRoute: Category | null) => {
    let route = `${category}/${slugify(
      categoryForRoute?.category_level1 as string,
    )}`
    for (let i = 2; i <= 3; i++) {
      // @ts-expect-error it can index here just weird types :)
      const category = categoryForRoute?.[`category_level${i}`]
      if (category != null && category !== '') {
        route += `/${slugify(category)}`
      }
    }

    return route
  },
  searchProductsRoute: (searchTerm?: string | null) =>
    `${searchProductsRoute}/${searchTerm}`,
  searchCataloguesRoute: (searchTerm?: string | null) =>
    `${searchCataloguesRoute}/${searchTerm}`,
  retailersRoute: (retailerName: string) =>
    `${retailers}/${slugify(retailerName)}`,
  searchRoute: (searchTerm?: string | null) => `${search}/${searchTerm}`,
  supportRoute: (topic: string) => `${support}/${slugify(topic)}`,
}

export const routeCalculator = Object.fromEntries(
  Object.entries(routeCalculatorRelative).map(([k, v], _i) => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (typeof v === 'string') {
      return [k, getAbsoluteUrl(v)]
    } else if (typeof v === 'function') {
      const absoluteUrl = (...args: string[]) => {
        // eslint-disable-next-line prefer-spread
        const path = (v as Function).apply(null, args) as string

        return getAbsoluteUrl(path)
      }
      return [k, absoluteUrl]
    } else {
      return [k, v]
    }
  }),
)
