import { type User } from '@/lib/generated/graphql'
import { type FetchUserAttributesOutput } from '@aws-amplify/auth'
import { createContext, useContext } from 'react'

export const useLogin = () => useContext(LoginContext)

export interface LoginContextProps {
  loggedIn: boolean
  userAttributes: FetchUserAttributesOutput | undefined
  setUserAttributes: (user?: FetchUserAttributesOutput) => void
  setLoggedIn: (loggedIn: boolean) => void
  openOnboarding: () => void
  closeOnboarding: () => void
  setShowPostcode: (show: boolean) => void
  openLogin: () => void
  user?: User
}

export const LoginContext = createContext<LoginContextProps>({
  loggedIn: false,
  openOnboarding: () => {
    throw new Error('not ready')
  },
  closeOnboarding: () => {
    throw new Error('not ready')
  },
  userAttributes: undefined,
  setUserAttributes: () => {
    throw new Error('not ready')
  },
  setLoggedIn: () => {
    throw new Error('not ready')
  },
  setShowPostcode: () => {
    throw new Error('not ready')
  },
  openLogin: () => {
    throw new Error('not ready')
  },
})
